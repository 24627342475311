<template>
  <section class="editCommodity-wrapper">
    <card title="新增商品">
      <el-button
        class="card-btn"
        size="small"
        type="primary"
        @click="$router.back(-1)"
        >返回</el-button
      >
      <el-form
        ref="form"
        :model="product"
        label-width="100px"
        class="commodity-form"
        :rules="rule"
      >
        <el-form-item label="商品名称" prop="pname">
          <el-input v-model="product.pName" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="商品价格" prop="price">
          <el-input-number
            v-model="product.price"
            :step="0.1"
            :precision="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="商品详情图" prop="pic">
          <el-upload
            :action="imgUploadUrl"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccesspic"
            :file-list="picFile"
            list-type="picture-card"
            ref="upload"
            :limit="1"
            :class="{ disabled: uploadDisabled }"
            :on-change="onChange"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="商品类型" prop="tid">
          <el-select
            v-model="tid"
            placeholder="请选择"
            class="select-type"
            @change="typeChange"
          >
            <el-option
              v-for="item in typelist"
              :key="item.tid"
              :label="item.tname"
              :value="item.tid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="适用年龄" prop="age">
          <el-input-number v-model="downAge" :step="1"></el-input-number>
          <el-select
            v-model="ageType[0]"
            placeholder="请选择"
            class="select-data"
          >
            <el-option label="天" :value="0"></el-option>
            <el-option label="月" :value="1"></el-option>
            <el-option label="年" :value="2"></el-option>
          </el-select>
          —
          <el-input-number v-model="upAge" :step="1"></el-input-number>
          <el-select
            v-model="ageType[1]"
            placeholder="请选择"
            class="select-data"
          >
            <el-option label="天" :value="0"></el-option>
            <el-option label="月" :value="1"></el-option>
            <el-option label="年" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片描述" prop="pics">
          <el-upload
            :action="imgUploadUrl"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccesspics"
            :file-list="picsList"
            list-type="picture-card"
            ref="upload"
            :multiple="true"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="文字描述" prop="intro">
          <el-input
            type="textarea"
            v-model="product.intro"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </card>
    <el-dialog :visible.sync="imgPreview">
      <img width="100%" :src="imgPreviewUrl" alt="" />
    </el-dialog>
  </section>
</template>

<script>
import card from "../../components/card";
export default {
  components: {
    card
  },
  data() {
    var namecheck = (rule, value, callback) => {
      if (!this.product.pName) {
        callback(new Error("请填写商品名称"));
      } else {
        callback();
      }
    };

    var typecheck = (rule, value, callback) => {
      if (!this.tid) {
        callback(new Error("请选择商品类型"));
      } else {
        callback();
      }
    };
    var timecheck = (rule, value, callback) => {
      this.timePackage();
      if (this.product.upAge < this.product.downAge) {
        callback(new Error("适用年龄格式错误"));
      } else {
        callback();
      }
    };

    return {
      imgUploadUrl: "https://www.ymxjkx.com/api/back/product/uploadImage",
      imgPreviewUrl: "",
      imgPreview: false,
      picFile: [],
      picsList: [],
      tid: "",
      product: {
        pName: "",
        price: 0,
        downAge: 0,
        upAge: 0,
        type: {
          tid: 0,
          tname: ""
        },
        pic: "",
        picUrls: [],
        intro: ""
      },
      ageType: [0, 0],
      downAge: 0,
      upAge: 0,
      rule: {
        pname: [
          {
            validator: namecheck,
            trigger: "blur"
          }
        ],
        tid: [
          {
            validator: typecheck,
            trigger: "change"
          }
        ],
        age: [
          { validator: timecheck, trigger: "blur" },
          { validator: timecheck, trigger: "change" }
        ]
      },
      uploadDisabled: false
    };
  },
  computed: {
    typelist() {
      return this.$store.state.typelist;
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.timePackage();

          let url = this.$route.query.pid
            ? "/back/product/updateProduct"
            : "/back/product/addProduct";

          this.$axios({
            url: url,
            method: "post",
            transformRequest: [
              function(data) {
                return JSON.stringify(data);
              }
            ],
            data: this.product,
            headers: { "Content-Type": "application/json" }
          }).then(res => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: this.$route.query.pid
                  ? "修改商品信息成功"
                  : "添加商品成功"
              });
              this.$router.back(-1);
            } else {
              this.$message({
                type: "error",
                message: "添加商品失败"
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    onChange(file, fileList) {
      this.uploadDisabled = fileList.length ? true : false;
      console.log(fileList);
    },
    timePackage() {
      const timeMap = [1, 30, 365];
      this.product.downAge = this.downAge * timeMap[this.ageType[0]];
      this.product.upAge = this.upAge * timeMap[this.ageType[1]];
    },

    typeChange(val) {
      let tid = Number(val);
      this.product.type.tid = tid;
      this.product.type.tname = this.typelist.find(
        item => item.tid == tid
      ).tname;
    },

    handleRemove(file, fileList) {
      this.$axios({
        url: "/back/product/deleteImage",
        params: {
          fileUrl: file.url
        }
      }).then(res => {
        this.product.pic === file.url.slice(7) && (this.product.pic = "");
        this.uploadDisabled = this.product.pic.length ? true : false;
        this.product.picUrls = this.product.picUrls.filter(item => {
          return item.purl !== file.url.slice(7);
        });
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "移除成功"
          });
        } else {
          this.$message({
            type: "error",
            message: "移除失败"
          });
        }
      });
    },
    handlePreview(file) {
      this.imgPreviewUrl = file.url;
      this.imgPreview = true;
    },
    handleSuccesspic(res, file, fileList) {
      this.product.pic = res.data;
    },
    handleSuccesspics(res, file, fileList) {
      this.product.picUrls.push({ purl: res.data });
    },
    ageConvert(age, type) {
      if (age / 365 > 0 && age % 365 == 0) {
        this.ageType[type] = 2;
        return age / 365;
      } else if (age / 365 > 0 && age % 365 !== 0) {
        if (age % 30 == 0) {
          this.ageType[type] = 1;
          return age / 30;
        } else {
          this.ageType[type] = 0;
          return age;
        }
      } else if (age >= 30 && age < 365) {
        if (age % 30 == 0) {
          this.ageType[type] = 1;
          return age / 30;
        } else {
          this.ageType[type] = 0;
          return age;
        }
      } else {
        this.ageType[type] = 0;
        return age;
      }
    }
  },
  created() {
    if (this.$route.query.pid) {
      this.product = this.$store.state.product;
      this.picFile = this.product.pic
        ? [{ name: "0", url: "https://" + this.product.pic }]
        : [];
      this.uploadDisabled = this.picFile.length;
      for (let item of this.product.picUrls) {
        this.picsList.push({ name: item.pid, url: "https://" + item.purl });
      }
      this.tid = this.product.type.tid;
      this.downAge = this.ageConvert(this.product.downAge, 0);
      this.upAge = this.ageConvert(this.product.upAge, 1);
    }
  }
};
</script>

<style lang="less">
.disabled .el-upload--picture-card {
  display: none;
}
.commodity-form {
  min-width: 680px;
  .select-type {
    .el-input {
      width: 300px !important;
    }
  }
  .select-data {
    .el-input {
      width: 100px !important;
      margin-left: 10px;
    }
  }
}
</style>
